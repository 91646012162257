import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs';

import { selectPatient } from '@abbadox-monorepo/kiosk-patient-data-access';
import {
  selectCurrentStep,
  selectNextStep,
  selectPageFooter,
  selectSelectedWorkflow,
} from '@abbadox-monorepo/kiosk-workflows-data-access';

import { BillingApiActions, BillingPageActions, selectPaymentSubmitted } from './billing.state';

/**
 * Effects in this file function as an event bus for incoming API actions.
 * This means that route transitions get handled here while state is modified in API effects.
 *
 * These effects depend on the workflow name to properly set the correct route.
 * If page configs don't have a page for a route, they simply won't transition although calling an
 * API resource.
 */

/** Navigate to billing page. */
export const navigateToBillingPage$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(BillingPageActions.navigateToBilling),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectCurrentStep),
        store.select(selectNextStep),
        store.select(selectPatient),
      ),
      tap(([_, workflow, currentStep, nextStep, patient]) => {
        if (patient && nextStep === 'billing') {
          router.navigate([workflow, currentStep, patient.autoCount, nextStep]);
        }
      }),
    ),
  { functional: true, dispatch: false },
);

/** Navigate to the confirmation. */
export const afterFinalizePayment$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(BillingApiActions.submitPaymentSuccess, BillingApiActions.cancelPayment),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectPageFooter),
        store.select(selectPaymentSubmitted),
        store.select(selectPatient),
      ),
      tap(([_, workflow, pageFooter, submitted, patient]) => {
        if (patient && submitted && pageFooter.nextStep === 'confirmation') {
          router.navigate([workflow, 'appointment-verification', patient.autoCount, pageFooter.nextStep]);
        }
      }),
    ),
  { functional: true, dispatch: false },
);
