import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';

import { NotificationData } from '@abbadox-monorepo/shared-ui';

export const BillingApiActions = createActionGroup({
  source: 'Billing Api',
  events: {
    submitPaymentAttempted: emptyProps(),
    submitPaymentSuccess: emptyProps(),
    submitPaymentFailed: props<{ error: NotificationData }>(),
    cancelPayment: emptyProps(),
  },
});

export const BillingPageActions = createActionGroup({
  source: 'Billing Page',
  events: {
    navigateToBilling: emptyProps(),
  },
});

export interface BillingState {
  amountOwed: number;
  paymentSubmitted: boolean;
  error: any;
}

export const initialBillingState: BillingState = {
  amountOwed: 0,
  paymentSubmitted: false,
  error: null,
};

export const billingFeature = createFeature({
  name: 'billing',
  reducer: createReducer(
    initialBillingState,
    on(BillingApiActions.submitPaymentAttempted, (state) => ({
      ...state,
      paymentSubmitted: true,
    })),
  ),
});

export const {
  name: BILLING_STATE_FEATURE_KEY,
  reducer: billingReducer,
  selectAmountOwed,
  selectPaymentSubmitted,
} = billingFeature;
